import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
  I’m an aspiring <b className="purple">web penetration tester</b> with a strong drive to become a <b className="purple">red teamer</b> in the future. 
  I’m currently diving into <b className="purple">bug bounty hunting</b> to sharpen my skills and deepen my understanding of real-world vulnerabilities.
  <br />
  <br />
  When I’m not hunting <b className="purple">bugs</b>, I dive into <b className="purple">blogs</b>, <b className="purple">write-ups</b>, and <b className="purple">bounty reports</b>, soaking in lessons from the best in the field. I also spend time researching <b className="purple">cutting-edge exploits</b> and honing my skills to stay ahead in the ever-evolving world of <b className="purple">cybersecurity</b>.
  <br />
  <br />
  I’m well-versed with tools like <b className="purple">Burp Suite</b> and am always on the lookout for the next tool or trick to add to my ethical hacking toolkit. 
  <br />
  <br />
  Let’s just say, I don’t just <b className="purple">write</b> code; I <b className="purple">dissect</b> it, <b className="purple">debug</b> it, and sometimes just <b className="purple">blow</b> it up—because every explosion leads to a new <b className="purple">discovery</b>!
  </p>

          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/vaaditya320"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/vaaditya320"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/vaaditya320/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/vaaditya320/profilecard/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
