import React from "react";
import { Col, Row } from "react-bootstrap";

import ciscoEH from '../../Assets/ciscoEH.png';

function Certifications() {
    return (
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons">
        <img src={ciscoEH} alt="Cisco Ethical Hacker" style={{ width: "100%" }} />
        </Col>
      </Row>
    );
  }
  
  export default Certifications;