import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { initGA, logPageView } from "./utils/analytics"; // Import analytics functions
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Component to track page views for route changes
const GAListener = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname); // Log the current page view
  }, [location]);

  return children;
};

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  // Initialize Google Analytics when the app loads
  useEffect(() => {
    initGA();
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <GAListener>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bounty" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Routes>
        </GAListener>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
