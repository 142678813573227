import ReactGA from "react-ga4";

// Initialize Google Analytics
export const initGA = () => {
  ReactGA.initialize("G-DG90VPCVJS"); // Replace with your Measurement ID
};

// Log page views
export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
